/**
 * Generic API file to handle all requests this React app needs to make
 * Currently also does some data cleansing / manipulation. Ideally can move this piece to server side
 * @author BrianCastor
*/

//HTTP request lib
const axios = require('axios')

const API_BASE_URL = "https://hopeonesource-api.herokuapp.com/api/";
//const API_BASE_URL = 'http://localhost:5000/api/'
var API = axios.create({
    baseURL: API_BASE_URL,
});
// Add a response interceptor for better error messages
API.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // If we have an error message from the server, return that as the error
    if (error.response && error.response.data && error.response.data.message) {
        return Promise.reject(error.response.data.message);
    }
    else {
        return Promise.reject(error);
    }
});

const APIFunctions = {
    getAllServiceCategories() {
        return ['Case Management', 'Child Care', 'Emergency / Safety', 'Food / Meals', 'Homeless Prevention', 'Housing Assistance', 'Job Placement', 'Medical Assistance', 'Mental Health / Crisis Support', 'Legal Assistance', 'Peer Support', 'Shelter', 'Transportation', 'Clothing / Haircuts', 'Education / Training', 'Financial / Credit Counseling', 'Restrooms / Showers'];
    },

    getAllDemographicCategories() {
        return ['Disconnected / Unaccompanied Youth', 'Ex-Offenders / Returning Citizens', 'Families','Non-Citizens / Undocumented Migrants','LGBTQ','Recovering Substance Users','Single Adult Males','Single Adult Females','Survivors of Crime / Domestic Violence','Veterans','Seniors / Elderly'];
    },

    cleanUserData(userData) {
        if (!userData) {
            return null;
        }

        let user = {...userData};

        //Remove dummy contact information (from previous iteration of this app)
        if (user.Id === '003L000001KDaKcIAL') {
            return null;
        }

        //Clean services to only valid services
        if (user.Services_MPL__c) {
            user.Services_MPL__c = Array.isArray(user.Services_MPL__c) ? user.Services_MPL__c : [];
            let validServices = this.getAllServiceCategories();
            user.Services_MPL__c = user.Services_MPL__c.filter(val => validServices.includes(val));
        }

        //Clean demographics to only valid demographics
        if (user.Demographics_MPL__c) {
            user.Demographics_MPL__c = Array.isArray(user.Demographics_MPL__c) ? user.Demographics_MPL__c : [];
            let validDemos = this.getAllDemographicCategories();
            user.Demographics_MPL__c = user.Demographics_MPL__c.filter(val => validDemos.includes(val));
        }

        return user;
    },


    async createContact(contactData) {
        let response = await API.post('v1/contact', contactData);
        return response.data;
    },
    async getContactById(id) {
        let response = await API.get(`v1/contact?id=${id}`);
        return response.data;
    },

    async getPostingById(id) {
        let response = await API.get(`v1/posting?id=${id}`);
        return response.data;
    },
    async getRecentPostings() {
        let response = await API.get('v1/postings?limit=6');
        return response.data;
    },
    async getAccountById(id) {
        let response = await API.get(`v1/account?id=${id}`);
        return response.data;
    },
    async getRecentAccounts() {
        let response = await API.get('v1/accounts?limit=6');
        return response.data;
    },

    async generateMatches(contactData, favoritedRecords=[], rejectedRecords=[]) {
        let data = {...contactData, favoritedRecords:favoritedRecords, rejectedRecords:rejectedRecords}
        let response = await API.post('v1/generateMatches', data);
        return response.data;
    },

    async logSwipe(contactId, swipedRecordId, swipedRecordType, decision) {
        let data = {
            'Contact__c':contactId,
            'Decision__c':decision
        };

        if (swipedRecordType === 'Account') {
            data['Service_Provider__c'] = swipedRecordId
        }
        else if (swipedRecordType === 'Posting__c') {
            data['Posting__c'] = swipedRecordId
        }
        else {
            console.error(`${swipedRecordType} is not a valid record type for a swiped record`)
            return;
        }

        let response = await API.post('v1/swipe', data);
        return response.data;
    },

    async removeSwipe(contactId, swipedRecordId, swipedRecordType) {
        let data = {
            'Contact__c':contactId
        }
        if (swipedRecordType === 'Account') {
            data['Service_Provider__c'] = swipedRecordId
        }
        else if (swipedRecordType === 'Posting__c') {
            data['Posting__c'] = swipedRecordId
        }
        else {
            console.error(`${swipedRecordType} is not a valid record type for a swiped record`)
            return;
        }

        let response = await API.delete('v1/swipe', {data:data});
        return response.data;
    },

    async register(userData) {
        const VALID_FIELDS = ['Id', 'FirstName', 'LastName', 'Email', 'MobilePhone', 'BirthDate', 'Services_MPL__c', 'Demographics_MPL__c', 'Language_PL__c', 'how_far_to_receive__c', 'MailingStreet', 'MailingCity', 'MailingState', 'MailingCountry', 'MailingPostalCode',
        'Most_helpful_to_stability_situation__c', 'Hope_Rating__c', 'userRole', 'Birthdate'
        ];
        
        let body = {}
        VALID_FIELDS.forEach(field => {
            if (field in userData) {
                body[field] = userData[field]
            }
        })

        body['npe01__PreferredPhone__c'] = 'Mobile';

        let response = await API.post('v1/register', body);
        return response.data;
    },

    async updateContact(userData) {
        const VALID_FIELDS = ['Id', 'FirstName', 'LastName', 'Email', 'MobilePhone', 'BirthDate', 'Services_MPL__c', 'Demographics_MPL__c', 'Language_PL__c', 'how_far_to_receive__c', 'MailingStreet', 'MailingCity', 'MailingState', 'MailingCountry', 'MailingPostalCode',
        'Most_helpful_to_stability_situation__c', 'Hope_Rating__c', 'userRole', 'Birthdate'
        ];
        
        let body = {}
        VALID_FIELDS.forEach(field => {
            if (field in userData) {
                body[field] = userData[field]
            }
        });

        body['npe01__PreferredPhone__c'] = 'Mobile';
        
        let response = await API.put('v1/contact', body);
        return response.data;
    },

    async getFavorites(favoritedRecordIds) {
        let response = await API.post('v1/records', {'ids':favoritedRecordIds});
        return response.data;
    },

    async getStatistics() {
        let response = await API.get('v1/totals');
        return response.data.count;
    },

    async getGeolocations() {
        let response = await API.get('v1/geolocations');
        return response.data;
    },
 }

 export default APIFunctions