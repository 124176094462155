import React from "react";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

/**
 * Global Material UI theme specification (primary colors, etc.). This theme is passed into all pages currently
 * @author BrianCastor
 */
const theme = createMuiTheme({
    palette: {
        primary: {
            light: 'rgb(251, 176, 59)',
            main: '#c1272d',
            dark: 'rgb(251, 176, 59)',
            contrastText: '#fff',
        },
    },
    typography:{
        fontFamily:"Roboto"
    }
});

const MaterialUITheme = ({ children }) => (
    <ThemeProvider theme={theme}>
        {children}
    </ThemeProvider>
)
export default MaterialUITheme;