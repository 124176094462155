
/**
 * Global Data Store for this Gatsby application (stores user, posting, account, favorite, and rejected information).
 * Data and methods to update this data is passed down in this app via the React Context API.
 * @author BrianCastor
 * 
 * In React class, invoke this via:
 * import { globalContext } from "../GlobalContext"
 * In class: contextType = globalContext;
 * Reference a data attribute or method in render(): this.context.property
 * 
 * In a React functional component, invoke this via:
 * import { globalContext } from "../GlobalContext"
 * In function: const context = useContext(globalContext);
 * Reference data attribute or method: context.property
 */

import React, { useState, useEffect } from "react"
import {navigate} from 'gatsby';

import API from './API';

//Create our Global Context
export const globalContext = React.createContext(null);


//Get our data from LocalStorage
const localFavoritedPostingIds = typeof window !== 'undefined' && JSON.parse(localStorage.getItem("favoritedPostingIds"));
const localRejectedPostingIds = typeof window !== 'undefined' && JSON.parse(localStorage.getItem("rejectedPostingIds"));
const localUserPreferences = typeof window !== 'undefined' && JSON.parse(localStorage.getItem("userPreferences"));

const GlobalContext = props => {
    const [currentUser, setCurrentUser] = useState(localUserPreferences || null);
    const [favoritedPostingIds, setFavoritedPostingIds] = useState(localFavoritedPostingIds || []);
    const [rejectedPostingIds, setRejectedPostingIds] = useState(localRejectedPostingIds || []);

    //Save our data into LocalStorage when each attribute changes
    useEffect(() => {
        localStorage.setItem("favoritedPostingIds", JSON.stringify(favoritedPostingIds));
    }, [favoritedPostingIds]);

    useEffect(() => {
        localStorage.setItem("rejectedPostingIds", JSON.stringify(rejectedPostingIds));
    }, [rejectedPostingIds]);

    useEffect(() => {
        if (currentUser) {
            setCurrentUser(API.cleanUserData(currentUser));
        }
    }, []);

    useEffect(() => {
        //Temporary code to clean up localstorage
        localStorage.setItem("userPreferences", JSON.stringify(currentUser));
    }, [currentUser]);

    //Methods and data that are passed via the Context API
    return (
        <globalContext.Provider value={{
            //Current user data
            currentUser,
            //ID's of any postings or accounts that the user has favorited
            favoritedPostingIds,
            //ID's of any postings or accounts that the user has rejected
            rejectedPostingIds,
            isRegistered: () => { return currentUser ? Object.keys(currentUser).includes('Id') : false},
            /**
             * Update global user preferences
            */
            updateUserPreferences: (userData) => { setCurrentUser(Object.assign({...currentUser}, userData)) },
            /**
             * Clear user preferences
             */
            clearUserPreferences: () => { 
                setFavoritedPostingIds([]);
                setRejectedPostingIds([]);
                setCurrentUser(null);
            },
            /**
             * Favorite a posting or service provider
             */
            favoritePosting: async (recordId, recordType) => { 
                setFavoritedPostingIds(favoritedPostingIds.concat([recordId]));
                try {
                    await API.logSwipe(currentUser.Id, recordId, recordType, 'Favorited'); 
                }
                catch(err) {
                    console.error(err);
                }
            },
            /**
             * Unfavorite a posting or service provider
             */
            unFavoritePosting: async (recordId, recordType) => {
                let favorites = [...favoritedPostingIds];
                favorites.splice(favoritedPostingIds.indexOf(recordId), 1);
                setFavoritedPostingIds(favorites);
                try {
                    await API.removeSwipe(currentUser.Id, recordId, recordType); 
                }
                catch(err) {
                    console.error(err);
                }
            },
            /**
             * Reject (hide) a posting or service provider
             */
            rejectPosting: async (recordId, recordType) => { 
                setRejectedPostingIds(rejectedPostingIds.concat([recordId])) 
                try {
                    await API.logSwipe(currentUser.Id, recordId, recordType, 'Rejected'); 
                }
                catch(err) {
                    console.error(err);
                }
            },
            /**
             * Reset rejected postings and service providers
             */
            resetRejectedPostings: () => {
                setRejectedPostingIds([]);
            },
            /**
             * Navigate to a specific endpoint in our application - protected against SSR errors
             * @param {string} endpoint 
             */
            navigateSafe: (endpoint) => {
                //Prevent Gasby SSR from breaking
                if (typeof window !== "undefined") {
                    navigate(endpoint);
                }
            }
        }}>
            {props.children}
        </globalContext.Provider>
    )
};

const GlobalContextComponent = ({ children }) => (
    <GlobalContext>
        {children}
    </GlobalContext>
)

export default GlobalContextComponent;