/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

/*Give our app a global state*/
import React from "react";
import GlobalContext from './src/GlobalContext';
import MaterialUITheme from './src/materialUITheme';

export const wrapRootElement = ({ element }) => {
    return <GlobalContext><MaterialUITheme>{element}</MaterialUITheme></GlobalContext>
}
